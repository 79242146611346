import { twMerge } from "tailwind-merge";
import Heading from "../layout/Heading";
import Gutter from "../layout/Gutter";

export interface TextHeroProps extends React.HTMLAttributes<HTMLDivElement> {
  text: string;
}

const lineStyles = [
  "text-blu-500 text-3xl",
  "text-gray-800 font-medium text-lg italic",
];

const TextHero: React.FC<TextHeroProps> = ({ text, className, ...props }) => {
  return (
    <div
      {...props}
      className={twMerge(
        "bg-gradient-to-b from-white to-gray-100 px-8 py-12 text-center",
        className,
      )}
    >
      <Gutter>
        <Heading className={twMerge("mx-auto max-w-3xl space-y-4")}>
          {text.split("\n").map((line, i) => (
            <div
              key={i}
              className={twMerge("m-0", lineStyles[i % lineStyles.length])}
            >
              {line}
            </div>
          ))}
        </Heading>
      </Gutter>
    </div>
  );
};
export default TextHero;
