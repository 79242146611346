import type { FormEvent } from "react";
import React, { useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";
import Input from "./forms/Input";
import Button from "./layout/Button";
import { useTranslation } from "react-i18next";
import type { ZodError } from "zod";
import { z } from "zod";
import Card from "./layout/Card";
import { Form } from "@remix-run/react";
import { env } from "~/util/env";

/**
 * srcSet can either be used natively or by passing an array of  { options: cloudflare transformation options, width: css width }
 * e.g. { options: { width: 400, quality: 80 }, width: "500w" }
 */
export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  formSlug: string;
}

type Form = {
  formSlug: string;
  name: string;
  email: string;
  phone: string;
  message: string;
};

export const ContactForm: React.FC<Props> = ({
  formSlug,
  className,
  ...props
}) => {
  const { t } = useTranslation();
  const [state, setState] = useState<"idle" | "loading" | "error" | "success">(
    "idle",
  );
  const [zodError, setZodError] = useState<ZodError | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [value, setValue] = useState<Form>({
    formSlug,
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const schema = useMemo(
    () =>
      z.object({
        name: z.string().min(1, { message: t("contact.error.name") }),
        email: z.string().email(t("contact.error.email")),
        phone: z.string().nullable(),
        message: z.string().min(1, { message: t("contact.error.message") }),
      }),
    [t],
  );

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setState("loading");

    // validate the schema
    const parsed = schema.safeParse(value);
    if (parsed.success) {
      setZodError(null);
    } else {
      setZodError(parsed.error);
      setState("error");
      return;
    }

    // post form to PayloadCMS
    const response = await fetch(`${env().BACKEND_URL}/api/formSubmissions`, {
      method: "post",
      body: JSON.stringify(value),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    if (!response.ok) {
      try {
        const json = await response.json();

        if (json.errors) {
          setSubmitError(json.errors[0]?.message || t("contact.error.submit"));
          setState("error");
          return;
        } else {
          setSubmitError(t("contact.error.submit"));
          setState("error");
          return;
        }
      } catch (error) {
        setSubmitError(t("contact.error.submit"));
        setState("error");
        return;
      }
    }

    setState("success");
  };
  return (
    <Card
      {...props}
      className={twMerge("grid max-w-xl grid-cols-1 gap-4", className)}
      look="gray"
    >
      {state === "success" ? (
        <div className=" h-100 flex flex-col items-center justify-center gap-8 text-center font-medium">
          <div className="i-solar:chat-round-check-linear text-yello-500 p-10 text-4xl" />
          {t("contact.success")}
        </div>
      ) : (
        <Form
          className="grid grid-cols-1 gap-4"
          onSubmit={handleSubmit}
          navigate={false}
          noValidate
        >
          <Input
            name="name"
            label={t("contact.label.name")}
            value={value.name}
            onChange={(e) => setValue({ ...value, name: e.target.value })}
            zodError={zodError}
            readOnly={state === "loading"}
            required
          />
          <Input
            type="email"
            name="email"
            label={t("contact.label.email")}
            value={value.email}
            onChange={(e) => setValue({ ...value, email: e.target.value })}
            zodError={zodError}
            readOnly={state === "loading"}
            required
          />
          <Input
            type="tel"
            name="phone"
            label={t("contact.label.phone")}
            value={value.phone}
            onChange={(e) => setValue({ ...value, phone: e.target.value })}
            readOnly={state === "loading"}
            zodError={zodError}
          />
          <Input
            tag="textarea"
            name="message"
            label={t("contact.label.message")}
            value={value.message}
            onChange={(e) => setValue({ ...value, message: e.target.value })}
            zodError={zodError}
            readOnly={state === "loading"}
            required
          />
          <Button
            type="submit"
            look={"yello"}
            className="w-full"
            disabled={state === "loading"}
          >
            {t("contact.submit")}
          </Button>
          {submitError && (
            <div className="rounded border border-red-200 bg-red-100 p-4">
              {submitError}
            </div>
          )}
        </Form>
      )}
    </Card>
  );
};

export default ContactForm;
